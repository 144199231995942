<template>
  <v-navigation-drawer class="candidate-view" v-model="showDrawer" app stateless right width="400">
    <v-card v-if="candidate" class="overflow-hidden rounded-0">
      <v-app-bar fixed color="primary" class="elevation-1" dense extension-height="96">
        <v-row justify="start" align="center" class="white--text">
          <v-col>
            <div
              class="body-1 text-no-wrap"
              style="display: block"
            >{{ candidate.firstName }} {{ candidate.lastName }}</div>
            <div style="margin-top: -5px" class="body-2 font-weight-bold">{{candidate.primaryPhone}}</div>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <template v-slot:extension>
          <v-col class="grey lighten-4 pa-0">
            <v-row
              no-gutters
              class="py-3 pl-2 pr-2"
              style="border-left: 1px solid #e0e0e0; border-bottom: 1px solid #ddd"
            >
              <v-chip v-if="candidateClock" outlined small label class="mr-1">
                <v-icon small left>mdi-clock-outline</v-icon>
                {{candidateClock}}
              </v-chip>

              <v-spacer></v-spacer>

              <v-chip v-if="candidate.callbackTime" color="primary" dark small label class="mr-1">
                <v-icon small left>mdi-phone-outline</v-icon>
                {{candidate.callbackTime | datetime }}
              </v-chip>

              <v-spacer />

              <v-chip outlined small label>
                <v-icon small left>mdi-timer-outline</v-icon>
                <div class="mr-2">{{totalTime}}</div>
              </v-chip>
            </v-row>
            <v-tabs centered v-model="window" style="border-left: 1px solid #e0e0e0">
              <v-tab>Profile</v-tab>
              <v-tab>Notes</v-tab>
              <v-tab v-if="showRawData">
                <v-icon>mdi-code-tags</v-icon>
              </v-tab>
            </v-tabs>
          </v-col>
        </template>
      </v-app-bar>
      <v-card-text>
        <v-row justify="center" style="padding-top: 140px;padding-bottom: 40px">
          <v-window v-model="window">
            <v-window-item>
              <v-form class="px-2" :readonly="isEnrolled">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="ID"
                      outlined
                      hide-details
                      readonly
                      disabled
                      dense
                      v-model="candidate.id"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Org Patient ID"
                      outlined
                      hide-details
                      readonly
                      disabled
                      dense
                      v-model="candidate.ownerPatientId"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-row no-gutters class="overline mt-n3">Approved Devices</v-row>
                    <v-row no-gutters align="center">
                      <v-chip
                        v-for="device in candidate.devices"
                        :key="device"
                        label
                        outlined
                        color="primary"
                        class="font-weight-medium"
                      >{{ device | deviceName }}</v-chip>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="overline py-0">Profile</v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="First Name"
                      outlined
                      hide-details
                      dense
                      v-model="candidate.firstName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Last Name"
                      outlined
                      hide-details
                      dense
                      v-model="candidate.lastName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :items="GENDER"
                      label="Gender"
                      outlined
                      item-text="longName"
                      item-value="shortName"
                      hide-details
                      dense
                      v-model="candidate.gender"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <!-- <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="candidate.birthdate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">-->
                    <v-text-field
                      v-model="birthdate"
                      label="Birth Date"
                      prepend-inner-icon="mdi-calendar"
                      outlined
                      dense
                      v-mask="'##/##/####'"
                      hint="MM/DD/YYYY"
                      :rules="rules.birthdate"
                      persistent-hint
                    ></v-text-field>
                    <!-- </template> -->
                    <!-- <v-date-picker v-model="candidate.birthdate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(candidate.birthdate)"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-menu>-->
                  </v-col>
                  <v-col cols="12" class="overline py-0">Contact Information</v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Primary Phone"
                      outlined
                      hide-details
                      dense
                      v-mask="'###-###-####'"
                      v-model="candidate.primaryPhone"
                      append-icon="mdi-clipboard-text"
                      @click:append="copyToClipboard('primaryPhone')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="SMS Phone"
                      outlined
                      hide-details
                      dense
                      v-mask="'###-###-####'"
                      v-model="candidate.smsPhone"
                      append-icon="mdi-clipboard-text"
                      :error="invalidSmsPhone"
                      @click:append="copyToClipboard('smsPhone')"
                      @blur="checkSMSPhone"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Email"
                      outlined
                      hide-details
                      dense
                      v-model="candidate.email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="overline py-0">Address</v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Street"
                      outlined
                      hide-details
                      dense
                      v-model="candidate.address.line1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Street #2 (Apt/Unit)"
                      outlined
                      hide-details
                      dense
                      v-model="candidate.address.line2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="City"
                      outlined
                      hide-details
                      dense
                      v-model="candidate.address.city"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :items="STATES"
                      label="State"
                      outlined
                      item-text="longName"
                      item-value="shortName"
                      hide-details
                      dense
                      v-model="candidate.address.state"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Postal Code"
                      outlined
                      hide-details
                      dense
                      v-model="candidate.address.zip"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-window-item>
            <v-window-item>
              <v-card
                v-for="log in sortedLogs"
                :key="log.startTime"
                class="mx-auto my-4 elevation-0"
              >
                <v-card-title class="primary--text subtitle-2 py-0">
                  <div class="body-2">{{log.firstName}} {{log.lastName}}</div>
                  <div class="pl-1 pr-1">·</div>
                  <div class="grey--text">{{log.start | datetime}}</div>
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-icon small left color="primary">mdi-phone</v-icon>
                    <div class="caption font-weight-bold">{{log.status | formatStatus }}</div>
                  </v-row>
                  <div class>{{log.note}}</div>
                </v-card-text>
                <v-divider></v-divider>
              </v-card>
              <v-card v-if="!candidate.logs || candidate.logs.length === 0">
                <v-row align="center" justify="center" style="min-height: 320px">
                  <div>There are no activity logs</div>
                </v-row>
              </v-card>
            </v-window-item>
            <v-window-item class="font-weight-medium">{{candidate}}</v-window-item>
          </v-window>
        </v-row>
      </v-card-text>
      <!-- footer -->
      <div v-if="isEnrolled">
        <v-footer fixed class="footer pa-2">
          <v-btn
            class="flex-grow-1"
            depressed
            color="primary"
            dark
            @click="markAsEnrolled"
          >Remove From Enrollments</v-btn>
        </v-footer>
      </div>
      <div v-else>
        <v-footer v-if="isModified" fixed class="footer pa-2">
          <v-btn outlined color="primary" dark class="mr-2" @click="revertChanges">Undo Changes</v-btn>
          <v-btn class="flex-grow-1" depressed color="red" dark @click="updateProfile">
            <v-icon left>mdi-card-account-details-outline</v-icon>Save
          </v-btn>
        </v-footer>

        <v-footer v-else color="grey lighten-4" fixed class="footer pa-2">
          <v-btn depressed color="primary" class="mr-1 flex-grow-1" @click="logCall">
            <v-icon left>mdi-phone</v-icon>Done
          </v-btn>
          <v-btn depressed color="success" class="ml-1 flex-grow-1" @click="enrollCandidate">
            <v-icon left>mdi-account-star-outline</v-icon>Enroll
          </v-btn>
        </v-footer>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.candidate-view .v-sheet {
  box-shadow: none;
}

.candidate-view .v-item-group {
  width: 100%;
}

.candidate-view .footer {
  border-top: 1px solid #e0e0e0 !important;
}
</style>

<script>
import bus from 'common/helpers/bus'
import moment from 'moment-timezone'
import clone from 'lodash/cloneDeep'
import { GENDER, STATES, STATUS, STATUS_TYPES } from '@/consts'
import { mapActions, mapGetters, mapState } from 'vuex'

const bdayRegExp = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/

export default {
  data() {
    return {
      birthdate: '',
      GENDER,
      invalidPostalCode: false,
      invalidSmsPhone: false,
      menu: false,
      candidate: null,
      candidateCopy: null,
      candidateClock: null,
      candidateClockTimer: null,
      showDrawer: false,
      STATES,
      STATUS,
      timer: null,
      totalTime: 0,
      window: 0,
      isEnrolled: false,
      rules: {
        birthdate: [(v) => bdayRegExp.test(v) || 'Invalid birth date.'],
      },
    }
  },
  computed: {
    ...mapActions('timezone', ['getTimezones']),
    ...mapGetters('timezone', ['getTimezone']),
    ...mapState('enrollment', ['startCallTime']),
    showRawData() {
      return process.env.NODE_ENV === 'development'
    },
    // computedDateFormatted() {
    //   return this.formatDate(this.candidate.birthdate)
    // },
    isModified() {
      return (
        JSON.stringify(this.candidateCopy) !== JSON.stringify(this.candidate)
      )
    },
    sortedLogs() {
      let logs = this.candidate.logs || []
      return logs.slice(0).sort((a, b) => new Date(b.end) - new Date(a.end))
    },
  },
  filters: {
    deviceName(val = '') {
      switch (val.toUpperCase()) {
        case 'BP CUFF':
          return 'Blood Pressure Cuff'
        case 'BLOOD GLUCOSE MONITOR':
          return 'Blood Glucose Monitor'
        case 'WEIGHT SCALE':
          return 'Weight Scale'
      }
      return val
    },
    formatStatus(val) {
      if (val) {
        for (let i = 0; i < STATUS.length; i++) {
          if (STATUS[i].shortName === val) {
            return STATUS[i].longName
          }
        }
      }
      return 'Unknown'
    },
    date(value, includeDay) {
      if (value) {
        if (includeDay) {
          return moment(value).format('dddd, MMMM D, YYYY')
        }
        return moment(value).format('MMMM D, YYYY')
      }
      return ''
    },
    datetime(value, showTimeAgo) {
      if (value) {
        if (showTimeAgo) {
          return moment(value).tz(moment.tz.guess()).fromNow()
        }
        let dt = moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY')
        }
        return moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm a')
      }
      return ''
    },
  },
  watch: {
    birthdate(val = '') {
      if (bdayRegExp.test(val)) {
        this.candidate.birthdate = new Date(val).toISOString().split('T').shift() + 'T00:00:00Z'
      }
    },
  },
  methods: {
    ...mapActions('enrollment', [
      'initCallTime',
      'updateCandidate',
      'setCurrentCandidate',
      'getEnrollmentStatus',
      'getPatient',
      'updateCandidateStatus',
    ]),
    copyToClipboard(field) {
      this.$copyText(this.candidate[field]).then(
        function (e) {
          bus.$emit('message', {
            success: true,
            text: `Phone number copied "${e.text}"`,
            showClose: false,
          })
        },
        function () {
          bus.$emit('message', {
            error: true,
            text: 'Could not copy phone number',
            showClose: true,
          })
        }
      )
    },
    logCall() {
      bus.$emit('logCall')
    },
    enrollCandidate() {
      bus.$emit('enrollCandidate')
    },
    // formatDate(date) {
    //   if (!date) return null
    //   return moment(date).format('MMMM D, YYYY')
    // },
    revertChanges() {
      this.candidate = clone(this.candidateCopy)
      this.birthdate = moment(this.candidate.birthdate.split('T').shift()).format(
        'L'
      )
      this.checkSMSPhone()
    },
    startPatientClock() {
      this.stopPatientClock()
      this.candidateClock = moment(new Date()).format('h:mm a')
      this.candidateClockTimer = setInterval(() => {
        this.candidateClock = moment(new Date()).format('h:mm a')
      }, 1000)
    },
    stopPatientClock() {
      this.candidateClock = null
      clearInterval(this.candidateClockTimer)
    },
    async markAsEnrolled() {
      let status = STATUS_TYPES.ENROLLED
      let log = {
        start: new Date(this.startCallTime).toISOString(),
        end: new Date().toISOString(),
        status: status,
        note: '[System Note]: Already enrolled',
      }
      this.updateCandidateStatus({
        id: this.candidate.id,
        log,
      })
    },
    async checkSMSPhone() {
      if (!this.candidate.smsPhone) {
        this.invalidSmsPhone = false
        return
      }
      let patient = await this.getPatient({
        smsPhone: this.candidate.smsPhone,
      })
      this.invalidSmsPhone = false
      if (
        patient &&
        patient.ownerPatientId !== this.candidate.ownerPatientId
      ) {
        this.invalidSmsPhone = true
        bus.$emit('message', {
          text: `The SMS phone number is registered with another patient. Please ask the patient if they have another mobile number they can provide or you can remove it to enroll the patient.`,
          showClose: true,
          timeout: -1,
        })
      }
    },
    open(candidate) {
      this.initCallTime()

      this.candidate = clone(candidate)
      this.candidateCopy = clone(candidate)
      this.birthdate = moment(candidate.birthdate.split('T').shift()).format(
        'L'
      )

      let tz = this.getTimezone(this.candidate.address.zip)
      if (tz) {
        moment.tz.setDefault(tz)
        this.startPatientClock()
      } else {
        this.invalidPostalCode = true
      }
      this.timer = setInterval(() => {
        this.totalTime = moment(Date.now() - this.startCallTime).format('mm:ss')
      })

      // not using await to ensure async behavior
      this.getEnrollmentStatus({ candidate: this.candidate }).then(
        (isEnrolled = false) => {
          this.isEnrolled = isEnrolled
          if (isEnrolled) {
            bus.$emit('message', {
              text: `${this.candidate.firstName} ${this.candidate.lastName} has been recently enrolled. 
              Click "Remove From Enrollments" to remove from list.`,
              showClose: true,
            })
          }
        }
      )
      this.checkSMSPhone()
      this.showDrawer = true
    },
    close() {
      this.showDrawer = false
    },
    async updateProfile() {
      await this.checkSMSPhone()
      if (!this.invalidSmsPhone) {
        try {
          await this.updateCandidate(this.candidate)
          this.candidateCopy = clone(this.candidate)
          bus.$emit('message', {
            success: true,
            text: `${this.candidate.firstName} ${this.candidate.lastName} updated`,
            showClose: false,
          })
        } catch (e) {
          bus.$emit('message', {
            error: true,
            text: 'Error updating patient information: ' + e,
            showClose: true,
          })
        }
      }
    },
  },
}
</script>