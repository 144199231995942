<template>
  <v-row class="home" justify="center">
    <CandidateView ref="candidateView" />
    <v-col v-if="candidates">
      <v-tabs-items v-model="selectedTabIndex">
        <!-- All Patients -->
        <v-tab-item>
          <v-data-table
            v-show="selectedTabIndex === 0"
            :height="candidateTableHeight"
            :headers="headerPatients"
            :fixed-header="true"
            :items="filteredEnrollments"
            class="elevation-0"
            :page="page"
            :items-per-page="500"
            :hide-default-header="false"
            :hide-default-footer="true"
            @update:page="setPage"
          >
            <template v-slot:item="{ item }">
              <tr :ref="item.id" @click="selectRow(item)">
                <td style="text-align:center">{{ item.rowNum }}</td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.lastName }}</td>
                <td>
                  <div style="font-size:12px">{{ item.lastStatusUpdated | datetime(showTimeAgo) }}</div>
                </td>
                <td>
                  <div style="font-size: 13px">{{ item.lastNote }}</div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>

        <!-- Callbacks -->
        <v-tab-item>
          <v-row no-gutters class="pa-4" justify="center">
            <v-btn-toggle dense color="primary" v-model="callbackFilter">
              <v-btn value="all">All</v-btn>
              <v-btn value="today">Today</v-btn>
              <v-btn value="pastdue">Past Due</v-btn>
              <v-btn value="future">Future</v-btn>
            </v-btn-toggle>
          </v-row>
          <v-data-table
            v-show="selectedTabIndex === 1"
            sort-by="callbackTime"
            :sort-desc="false"
            :fixed-header="true"
            :height="callbacksTableHeight"
            :headers="headerCallbacks"
            hide-default-footer
            :items="filteredCallbacks"
            class="elevation-0"
            :page="page"
            :items-per-page="50"
            item-class="clickable"
            @click:row="selectRow"
            @update:page="setPage"
          >
            <template v-slot:item="{ item }">
              <tr :ref="item.id" @click="selectRow(item)">
                <td style="font-size:12px">{{ item.callbackTime | datetime(showTimeAgo) }}</td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.lastName }}</td>
                <td>
                  <div style="font-size: 13px">{{ item.lastNote }}</div>
                </td>
              </tr>
            </template>

            <!-- <template v-slot:item.status="{ item }">{{ item.status | formatStatus }}</template>
            <template v-slot:item.callbackTime="{ item }">
              <div style="font-size: 12px">{{ item.callbackTime | datetime(showTimeAgo) }}</div>
            </template>
            <template
              v-slot:item.lastStatusUpdated="{ item }"
            >{{ item.lastStatusUpdated | datetime(showTimeAgo) }}</template>
            <template v-slot:item.lastNote="{ item }">
              <div style="font-size: 13px">{{ item.lastNote }}</div>
            </template>-->
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-col>

    <v-progress-circular v-if="busy" class="loader" :size="50" color="grey lighten-2" indeterminate></v-progress-circular>

    <LogCallDialog />
    <EnrollDialog />
    <error-message-dialog ref="errMsgDialog"></error-message-dialog>

    <!-- Callback Snackbar -->
    <v-snackbar class="callback-snackbar" left bottom :value="showSnackbar" :timeout="-1">
      <v-icon color="white" left>mdi-phone-outline</v-icon>
      <span
        v-if="callbackFound"
      >{{ callbackFound.firstName }} {{ callbackFound.lastName }} {{ callbackFound.callbackTime | datetime }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn outlined v-bind="attrs" @click="viewCallbackPatient">View Profile</v-btn>
        <v-btn text @click="startCallbackSnooze()">Snooze</v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<style lang="scss" scoped>
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.callback-snackbar {
  z-index: 1;
}
</style>
<style lang="scss">
tr {
  cursor: pointer;
}
.switch-font label {
  font-size: 14px !important;
  font-weight: 500;
}
.selected-row {
  color: white;
  background: #1976d2 !important;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.candidate-view .v-toolbar__extension {
  padding: 0 !important;
}
</style>

<script>
import bus from 'common/helpers/bus'
import moment from 'moment-timezone'
import merge from 'lodash/merge'
import { mapActions, mapState } from 'vuex'
import { DEFAULT_FILTER_LIST, STATUS } from '@/consts'
import LogCallDialog from '../components/LogCallDialog.vue'
import EnrollDialog from '../components/EnrollDialog.vue'
import CandidateView from '../components/CandidateView.vue'
import ErrorMessageDialog from '../components/ErrorMessageDialog.vue'

const selectedClassName = 'selected-row'

export default {
  components: {
    LogCallDialog,
    EnrollDialog,
    ErrorMessageDialog,
    CandidateView,
  },
  data: () => {
    return {
      busy: true,
      callbackFilter: 'all',
      callbackFound: null,
      callbackTimer: null,
      callbackSnoozeTimer: null,
      errorMsg: null,
      filteredList: DEFAULT_FILTER_LIST,
      headerCallbacks: [
        { text: 'Callback', value: 'callbackTime', width: 110 },
        { text: 'First Name', value: 'firstName', width: 120 },
        { text: 'Last Name', value: 'lastName', width: 120 },
        { text: 'Notes', value: 'lastNote' },
      ],
      headerPatients: [
        { text: '', value: '', width: 32 },
        { text: 'First Name', value: 'firstName', width: 120 },
        { text: 'Last Name', value: 'lastName', width: 120 },
        { text: 'Last Call', value: 'lastStatusUpdated', width: 140 },
        { text: 'Notes', value: 'lastNote' },
      ],
      page: 1,
      showSnackbar: false,
      STATUS,
      statusFilter: null,
      todayCallbackOnly: false,
    }
  },
  computed: {
    ...mapState(['selectedTabIndex', 'showTimeAgo', 'searchInput']),
    ...mapState('enrollment', ['candidates', 'currentCandidate']),
    ...mapState('timezone', ['timezones']),
    candidateTableHeight() {
      return document.body.offsetHeight - 120
    },
    callbacksTableHeight() {
      return document.body.offsetHeight - 190
    },
    todayCallbacks() {
      const today = new Date()
      const callbacks = this.candidates.filter((enrollment) => {
        let d = new Date(enrollment.callbackTime)
        return (
          d.getDate() === today.getDate() &&
          d.getMonth() === today.getMonth() &&
          d.getFullYear() === today.getFullYear()
        )
      })
      const sortedCallbacks = callbacks
        .slice()
        .sort((a, b) => new Date(a.callbackTime) - new Date(b.callbackTime))
      return sortedCallbacks
    },
    filteredCallbacks() {
      const today = new Date()
      const callbacks = this.candidates.filter((enrollment) => {
        if (!enrollment.callbackTime) {
          return false
        }
        let d = new Date(enrollment.callbackTime)
        if (this.callbackFilter === 'today') {
          return (
            d.getDate() === today.getDate() &&
            d.getMonth() === today.getMonth() &&
            d.getFullYear() === today.getFullYear()
          )
        }
        if (this.callbackFilter === 'pastdue') {
          return d.getTime() <= today.getTime()
        }
        if (this.callbackFilter === 'future') {
          return d.getTime() > today.getTime()
        }
        return true
      })
      const sortedCallbacks = callbacks
        .slice()
        .sort((a, b) => new Date(a.callbackTime) - new Date(b.callbackTime))
      return sortedCallbacks
    },
    filteredEnrollments() {
      let rowNum = 1
      if (this.searchInput) {
        // search bar
        var regex = new RegExp(this.searchInput, 'i')
        return this.candidates.filter(c => {
          const toTest = [c.firstName, c.lastName, c.primaryPhone, c.smsPhone, c.ownerPatientId].join(' ')
          if (regex.test(toTest)) {
            c.rowNum = rowNum++
            return true
          }
          return false
        })
      } else if (this.filteredList.length) {
        return this.candidates.filter(c => {
          if (this.filteredList.indexOf(c.status) !== -1) {
            c.rowNum = rowNum++
            return true
          }
          return false
        })
      }
      return this.candidates || []
    },
  },
  filters: {
    formatStatus(val) {
      if (val) {
        for (let i = 0; i < STATUS.length; i++) {
          if (STATUS[i].shortName === val) {
            return STATUS[i].longName
          }
        }
      }
      return 'Unknown'
    },
    datetime(value, showTimeAgo) {
      if (value) {
        let dt = moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          // time was not set
          if (showTimeAgo) {
            return moment(value)
              .tz(moment.tz.guess())
              .fromNow()
              .split(' at 12:00 AM')[0]
          }
          return moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY')
        } else {
          if (showTimeAgo) {
            return moment(value).tz(moment.tz.guess()).fromNow()
          }
        }
        return dt
      }
      return ''
    },
  },
  methods: {
    ...mapActions('enrollment', ['setCurrentCandidate']),
    selectRow(item) {
      let selectedItems = document.getElementsByClassName(selectedClassName)
      for (let i = 0; i < selectedItems.length; i++) {
        selectedItems[i].classList.remove(selectedClassName)
      }
      this.setCurrentCandidate(item)
      if (item) {
        if (this.$refs[item.id]) {
          this.$refs[item.id].classList.add(selectedClassName)
        }
      }

      this.$refs.candidateView.open(this.currentCandidate)
    },
    setPage(page) {
      this.page = page
    },
    selectedRow(enrollment) {
      if (this.currentCandidate && enrollment.id === this.currentCandidate.id) {
        return selectedClassName
      }
      return ''
    },
    statusFilterChanged(status) {
      if (!this.filteredList.includes(status)) {
        this.filteredList.push(status)
      }
      this.$nextTick(() => {
        this.statusFilter = null
        this.$refs.filter.blur()
        this.page = 1
      })
    },
    removeFilter(filter) {
      this.filteredList = this.filteredList.filter((item) => {
        return filter !== item
      })
    },
    clearAllFilters() {
      this.filteredList = []
    },
    enrollmentUpdated(enrollment) {
      let len = this.candidates.length
      for (let i = 0; i < len; i++) {
        if (this.candidates[i].id == enrollment.id) {
          enrollment = merge({}, this.candidates[i], enrollment)
          this.$set(this.candidates, i, enrollment)
          break
        }
      }
    },
    startCallbackSearch() {
      this.stopCallbackSearch()
      this.callbackTimer = setInterval(() => {
        let now = new Date()
        let nowHours = now.getHours()
        let nowMins = now.getMinutes()
        let len = this.todayCallbacks.length
        for (let i = 0; i < len; i++) {
          const candidate = this.todayCallbacks[i]
          if (
            this.currentCandidate &&
            this.currentCandidate.id === candidate.id
          ) {
            continue
          }

          let d = new Date(candidate.callbackTime)
          if (d.getHours() === 0 && d.getMinutes() === 0) {
            continue
          }

          if (d.getHours() <= nowHours && d.getMinutes() <= nowMins) {
            this.callbackFound = candidate
            this.showSnackbar = true
            break
          }
        }
      }, 15000) // check 1x/min
    },
    stopCallbackSearch() {
      this.showSnackbar = false
      clearInterval(this.callbackTimer)
    },
    startCallbackSnooze(delay) {
      delay = delay || 60000 // default to 60 seconds
      this.stopCallbackSearch()
      this.stopCallbackSnooze()
      this.callbackSnoozeTimer = setTimeout(() => {
        this.startCallbackSearch()
      }, delay)
    },
    stopCallbackSnooze() {
      clearTimeout(this.callbackSnoozeTimer)
    },
    viewCallbackPatient() {
      this.startCallbackSnooze()
      this.setCurrentCandidate(this.callbackFound)
    },
  },
  watch: {
    searchInput() {
      this.setCurrentCandidate()
    },
    currentCandidate(candidate) {
      if (!candidate) {
        if (this.currentCandidate) {
          this.$refs[this.currentCandidate.id].classList.remove(
            selectedClassName
          )
        }
        let selectedItems = document.getElementsByClassName(selectedClassName)
        for (let i = 0; i < selectedItems.length; i++) {
          selectedItems[i].classList.remove(selectedClassName)
        }
      }
    },
    selectedTabIndex() {
      this.setCurrentCandidate()
    },
  },
  async created() {
    bus.$on('candidateUpdated', this.enrollmentUpdated)

    try {
      this.startCallbackSearch()
    } catch (e) {
      this.$refs.errMsgDialog.open(e)
    }
    this.busy = false
  },
}
</script>