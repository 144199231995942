import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueClipboard from 'vue-clipboard2'
import VueTheMask from 'vue-the-mask'
// import { setBaseUrl } from 'common/store/sso'

Vue.config.productionTip = false

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

Vue.use(VueTheMask)
// setBaseUrl(process.env.VUE_APP_SSO_API_URL)

setTimeout(async () => {
  const isValid = await store.dispatch('auth/validate')
  if (isValid) {
    store.dispatch('orgs/getOrgs')
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
      mounted() {
        document.getElementsByClassName('busy-spinner')[0].remove()
      },
    }).$mount('#app')
  }
})
