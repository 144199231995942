<template>
  <v-dialog v-model="showDialog" max-width="660" scrollable>
    <v-card v-if="currentCandidate">
      <v-toolbar dense color="grey lighten-3" flat>
        <v-toolbar-title>
          <v-row no-gutters align="center">
            <div class="mr-3">Enter call log</div>
          </v-row>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-form class="pt-5">
          <v-row>
            <div class="form-label">Candidate</div>
            <div class="form-value">{{ currentCandidate.firstName }} {{ currentCandidate.lastName }}</div>
          </v-row>
          <v-row>
            <div class="form-label pt-1">Call Result</div>
            <v-btn-toggle dense color="primary" v-model="result">
              <v-btn value="unavailable">Unavailable</v-btn>
              <v-btn value="no_callback">Remove from list</v-btn>
              <v-btn value="callback">Callback</v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row v-if="result === 'no_callback'" class="pt-4">
            <div class="form-label pt-1"></div>
            <v-select
              dense
              :items="reasons"
              label="Reason"
              outlined
              item-text="longName"
              item-value="shortName"
              hide-details
              v-model="reason"
            ></v-select>
          </v-row>
          <v-row v-if="result === 'callback'" class="pt-4" align="center">
            <div class="form-label pt-1"></div>
            <v-btn-toggle dense color="primary" v-model="callTime">
              <v-btn value="none">No Specific Time</v-btn>
              <v-btn value="1h">1 Hr</v-btn>
              <v-btn value="2h">2 Hrs</v-btn>
              <v-btn value="4h">4 Hrs</v-btn>
              <v-btn value="1d">{{buttonLabel}}</v-btn>
              <v-btn value="custom">Custom</v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row v-if="showCustomTimer" class="subtitle grey lighten-3 px-3 mt-5 rounded">
            <v-col>
              <v-row no-gutters>
                Callback
                <div
                  class="font-weight-bold px-1"
                >{{currentCandidate.firstName}} {{currentCandidate.lastName}}</div>on
                <div class="font-weight-bold px-1">{{callbackDate | date(true)}}</div>
                <div v-if="enableSpecificTime && callbackTime">
                  <span class="font-weight-bold">at {{candidateTimeDisplay}}</span>
                  their time
                </div>
              </v-row>
              <v-row v-if="enableSpecificTime && callbackTime" no-gutters>
                and at
                <div class="font-weight-bold px-1">{{callerTimeDisplay}}</div>your time
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="showCustomTimer" align="center" no-gutters class="mt-4">
            <v-spacer></v-spacer>
            <v-switch
              v-model="enableSpecificTime"
              dense
              hide-details
              inset
              label="Set specific time"
              color="success"
              class="switch-font pa-0 ml-2 mb-2 ma-0 pr-2"
            ></v-switch>
            <v-btn small outlined color="primary" class="mb-2 mr-1" @click="clearCallbackTime">Clear</v-btn>
          </v-row>
          <v-row v-if="showCustomTimer">
            <v-col>
              <v-date-picker v-model="callbackDate"></v-date-picker>
            </v-col>
            <v-col>
              <v-time-picker
                :disabled="!enableSpecificTime"
                :color="enableSpecificTime ? '' : 'grey lighten-1'"
                :header-color="enableSpecificTime ? '' : 'grey lighten-1'"
                v-model="callbackTime"
                :allowed-minutes="m => m % 15 === 0"
                @click:hour="hourChanged"
                ampm-in-title
              ></v-time-picker>
            </v-col>
          </v-row>
          <v-row class="pt-4">
            <div class="form-label pt-1">Notes</div>
            <v-textarea outlined hide-details no-resize rows="2" v-model="note"></v-textarea>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn text @click="showDialog = false" class="mr-3">Cancel</v-btn> -->
        <v-btn
          depressed
          color="primary"
          class="px-8"
          :disabled="!submitEnabled"
          @click="submitCallLog"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.form-label {
  padding-left: 12px;
  min-width: 100px;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
}
</style>

<script>
import bus from 'common/helpers/bus'
import moment from 'moment-timezone'
import { mapActions, mapGetters, mapState } from 'vuex'
import { STATUS, STATUS_TYPES } from '@/consts'

const reasons = [
  {
    longName: 'Wrong person / phone',
    shortName: STATUS_TYPES.WRONG_PERSON,
  },
  {
    longName: 'No longer a candidate',
    shortName: STATUS_TYPES.NOT_PATIENT,
  },
  {
    longName: 'Not interested',
    shortName: STATUS_TYPES.NOT_INTERESTED,
  },
  {
    longName: 'Already enrolled',
    shortName: STATUS_TYPES.ENROLLED,
  },
  {
    longName: 'Will contact provider',
    shortName: STATUS_TYPES.WILL_CONTACT_PROVIDER,
  },
  {
    longName: 'Other',
    shortName: STATUS_TYPES.OTHER,
  },
]

const today = moment(new Date()).format('dddd').toLowerCase()
const nextAvailCallbackDay = new Date()
nextAvailCallbackDay.setHours(0)
nextAvailCallbackDay.setMinutes(0)
nextAvailCallbackDay.setSeconds(0)
if (today === 'friday') {
  nextAvailCallbackDay.setDate(nextAvailCallbackDay.getDate() + 3)
} else if (today == 'saturday') {
  nextAvailCallbackDay.setDate(nextAvailCallbackDay.getDate() + 2)
} else {
  nextAvailCallbackDay.setDate(nextAvailCallbackDay.getDate() + 1)
}
let nextCallbackMonth = String(nextAvailCallbackDay.getMonth() + 1).padStart(
  2,
  '0'
)
let nextCallbackDate = String(nextAvailCallbackDay.getMonth() + 1).padStart(
  2,
  '0'
)
let nextCallbackDay = `${nextAvailCallbackDay.getFullYear()}-${nextCallbackMonth}-${nextCallbackDate}`

const updateStatusHandler = (candidate, status) => {
  status = formatStatus(status)
  bus.$emit('message', {
    success: true,
    text: `${candidate.firstName} ${candidate.lastName} status set to "${status}"`,
    showClose: false,
  })
}

const updateStatusErroHandler = (e) => {
  console.log(e)
  bus.$emit('message', {
    error: true,
    text: 'Error updating status',
    showClose: true,
  })
}

const formatStatus = (val) => {
  if (val) {
    for (let i = 0; i < STATUS.length; i++) {
      if (STATUS[i].shortName === val) {
        return STATUS[i].longName
      }
    }
  }
  return 'Unknown'
}

export default {
  data() {
    return {
      callbackDate: '',
      callbackTime: '',
      callerTimeDisplay: '',
      callTime: 'none',
      enableSpecificTime: false,
      invalidPostalCode: false,
      note: '',
      candidateClock: null,
      candidateClockTimer: null,
      candidateTimeDisplay: '',
      result: 'unavailable',
      showCallbackTime: false,
      showDialog: false,
      reason: null,
      reasons,
    }
  },
  computed: {
    ...mapState('enrollment', ['startCallTime', 'currentCandidate']),
    buttonLabel() {
      return moment.tz(nextAvailCallbackDay, moment.tz.guess()).format('dddd')
    },
    showCustomTimer() {
      return (
        this.result === 'callback' &&
        this.callTime === 'custom' &&
        this.candidateClock
      )
    },
    submitEnabled() {
      if (this.result === 'no_callback' && !this.reason) {
        return false
      }
      return true
    },
  },
  filters: {
    date(value, includeDay) {
      if (value) {
        if (includeDay) {
          return moment(value).format('dddd, MMMM D, YYYY')
        }
        return moment(value).format('MMMM D, YYYY')
      }
      return ''
    },
  },
  methods: {
    ...mapActions('enrollment', ['updateCandidateStatus']),
    ...mapGetters('timezone', ['getTimezone']),
    clearCallbackTime() {
      this.callbackTime = ''
      this.callerTimeDisplay = ''
      this.candidateTimeDisplay = ''
    },
    editStatus() {
      // this.currentCandidate = candidate
      this.reason = null
      ;(this.result = 'unavailable'), this.startClock()
      this.showDialog = true
    },
    hourChanged(val) {
      if (val >= 12) {
        val -= 12
      }
      if (val >= 0 && val <= 7) {
        val += 12
      } else if (val < 10) {
        val = '0' + val
      }
      this.callbackTime = val + ':00'
    },
    startClock() {
      this.stopClock()
      this.candidateClock = moment(new Date()).format('h:mm a')
      this.candidateClockTimer = setInterval(() => {
        this.candidateClock = moment(new Date()).format('h:mm a')
      }, 1000)
    },
    stopClock() {
      this.candidateClock = null
      clearInterval(this.candidateClockTimer)
    },
    getCallbackTime(val) {
      let d
      this.status = STATUS_TYPES.CALLBACK
      switch (val) {
        case '1h':
          d = new Date()
          d.setHours(d.getHours() + 1)
          break
        case '2h':
          d = new Date()
          d.setHours(d.getHours() + 2)
          break
        case '4h':
          d = new Date()
          d.setHours(d.getHours() + 4)
          break
        case '1d':
          d = nextAvailCallbackDay
          break
      }
      return d
    },
    async submitCallLog() {
      const candidate = this.currentCandidate
      if (this.result === 'unavailable') {
        let status = STATUS_TYPES.NOT_CONTACTED
        let log = {
          start: new Date(this.startCallTime).toISOString(),
          end: new Date().toISOString(),
          status: status,
          note: this.note,
        }
        this.updateCandidateStatus({
          id: candidate.id,
          log,
        })
          .then(() => updateStatusHandler(candidate, status))
          .catch(() => updateStatusErroHandler())
        this.showDialog = false
      } else if (this.result === 'no_callback') {
        let status = this.reason
        let log = {
          start: new Date(this.startCallTime).toISOString(),
          end: new Date().toISOString(),
          status: status,
          note: this.note,
        }
        this.updateCandidateStatus({
          id: candidate.id,
          log,
        })
          .then(() => updateStatusHandler(candidate, status))
          .catch(() => updateStatusErroHandler())
        this.showDialog = false
      } else if (this.callTime === 'none') {
        let status = STATUS_TYPES.NOT_CONTACTED
        let log = {
          start: new Date(this.startCallTime).toISOString(),
          end: new Date().toISOString(),
          status: status,
          note: this.note,
        }
        this.updateCandidateStatus({
          id: candidate.id,
          log,
        })
          .then(() => updateStatusHandler(candidate, status))
          .catch(() => updateStatusErroHandler())
        this.showDialog = false
      } else if (this.callTime === 'custom') {
        let tz = this.getTimezone()(candidate.address.zip)
        let callbackTime
        if (this.enableSpecificTime && this.callbackTime) {
          callbackTime = moment
            .tz(`${this.callbackDate} ${this.callbackTime}`, tz)
            .toISOString()
        } else {
          let [year, month, day] = this.callbackDate.split('-')
          let d = new Date(Number(year), Number(month) - 1, Number(day))
          callbackTime = d.toISOString()
        }
        let log = {
          start: new Date(this.startCallTime).toISOString(),
          end: new Date().toISOString(),
          status: STATUS_TYPES.CALLBACK,
          callbackTime,
          note: this.note,
        }
        this.updateCandidateStatus({
          id: candidate.id,
          log,
        })
          .then(() => updateStatusHandler(candidate, status))
          .catch(() => updateStatusErroHandler())
        this.showDialog = false
      } else {
        let callbackTime = this.getCallbackTime(this.callTime)
        let log = {
          start: new Date(this.startCallTime).toISOString(),
          end: new Date().toISOString(),
          status: STATUS_TYPES.CALLBACK,
          callbackTime: callbackTime.toISOString(),
          note: this.note,
        }
        this.updateCandidateStatus({
          id: candidate.id,
          log,
        })
          .then(() => updateStatusHandler(candidate, status))
          .catch(() => updateStatusErroHandler())
        this.showDialog = false
      }
    },
  },
  watch: {
    callbackTime(val) {
      if (val) {
        let candidateTimezone = this.getTimezone()(
          this.currentCandidate.address.zip
        )
        if (candidateTimezone) {
          let candidate = moment.tz(
            `${this.callbackDate} ${this.callbackTime}`,
            candidateTimezone
          )
          let caller = candidate.clone().tz(moment.tz.guess())
          this.candidateTimeDisplay = candidate.format('h:mm a')
          this.callerTimeDisplay = caller.format('h:mm a')
        }
      }
      return ''
    },
    showDialog() {
      this.enableSpecificTime = false
      this.showCallbackTime = false
      this.callbackDate = nextCallbackDay
      this.callbackTime = ''
      this.callTime = 'none'
      this.note = ''
    },
  },
  created() {
    bus.$on('logCall', this.editStatus)
  },
}
</script>