import { HttpClient } from 'common/helpers/httpClient'

const orgsClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_ORGS_API_URL,
})

export default {
  namespaced: true,
  state: {
    orgsMap: null,
    orgs: null,
  },
  mutations: {
    setOrgs(state, orgs) {
      state.orgs = orgs
      state.orgsMap = {}
      orgs.forEach(({ id, name }) => {
        state.orgsMap[id] = name
      })
    },
  },
  getters: {},
  actions: {
    async getOrgs({ commit }) {
      const { data } = await orgsClient.post('/GetOrgs')
      if (!data) return
      data.orgs.sort((a, b) => a.name.localeCompare(b.name))
      commit('setOrgs', data.orgs)
    },
  },
}
