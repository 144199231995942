import { HttpClient } from "common/helpers/httpClient"

const tzUrl =
  'https://firebasestorage.googleapis.com/v0/b/novo00.appspot.com/o/tz.data.txt?alt=media&token=77761fc0-c581-45f9-b619-cedb014711a7'

export default {
  namespaced: true,
  state: {
    timezones: null,
  },
  mutations: {
    setTimezones(state, timezones) {
      state.timezones = timezones
    },
  },
  getters: {
    getTimezone: state => postalCode => {
      if (!postalCode) return Intl.DateTimeFormat().resolvedOptions().timeZone
      postalCode = String(postalCode).split('-').shift()
      let regex = new RegExp(postalCode + '=.*', 'g')
      let timezone = state.timezones.match(regex)
      if (timezone) return timezone.pop().split("=").pop()
      return null
    },
  },
  actions: {
    async getTimezones({ commit }) {
      let { data } = await HttpClient.instance().get(tzUrl)
      commit('setTimezones', data)
    },
  },
}
