import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import orgs from './orgs'
import enrollment from './enrollment'
import timezone from './timezone'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    enrollment,
    orgs,
    timezone,
  },
  state: {
    ready: false,
    title: '',
    devMode: localStorage.getItem('dev_mode') === 'true',
    searchInput: '',
    selectedTabIndex: 0,
    showTimeAgo: true,
  },
  mutations: {
    setTitle(state, title) {
      state.title = title
      document.title = process.env.VUE_APP_TITLE + ' - ' + title
    },
    setReady(state, ready = false) {
      state.ready = ready
    },
    setDevMode(state, mode) {
      state.devMode = mode
      localStorage.setItem('dev_mode', mode)
    },
    setSearchInput(state, input) {
      state.searchInput = input
    },
    setSelectedTabIndex(state, index) {
      state.selectedTabIndex = index
    },
    setShowTimeAgo(state, value) {
      state.showTimeAgo = value
    },
  },
  actions: {
    toggleDevMode({ state, commit }) {
      commit('setDevMode', !state.devMode)
    },
    setSearchInput({ commit }, value) {
      commit('setSearchInput', value)
    },
    setSelectedTabIndex({ commit }, value) {
      commit('setSelectedTabIndex', value)
    },
    setShowTimeAgo({ commit }, value) {
      commit('setShowTimeAgo', value)
    },
  },
})
