import {
  clearTokens,
  getAccessToken,
  getUser,
  parseJWT,
  setRefreshToken,
} from 'common/store/sso'
import { getCookie, setCookie } from 'common/helpers/cookie'

import bus from 'common/helpers/bus'

let logoutTimer

const redirectToLogin = (_, queryParams = {}) => {
  let url = process.env.VUE_APP_SSO_APP_URL
  url +=
    '?redirect=' +
    encodeURIComponent(
      queryParams.redirect
        ? queryParams.redirect
        : location.origin + location.pathname
    )
  delete queryParams.redirect
  // if in dev mode, add query dev=1
  if (process.env.VUE_APP_MODE === 'development') {
    url += '&dev=1'
  }
  // redirect
  location.href = url
}

const logout = (_, queryParams = {}) => {
  // dispatch event to give the app a chance to do any cleanup
  bus.$emit('logout')
  // clear auth tokens
  clearTokens()

  // async call to before redirect
  clearTimeout(logoutTimer)
  logoutTimer = setTimeout(() => {
    // create url for redirect
    let url = process.env.VUE_APP_SSO_APP_URL
    url +=
      '/logout?redirect=' +
      encodeURIComponent(
        queryParams.redirect ? queryParams.redirect : location.origin
      )
    delete queryParams.redirect
    // attach query params
    if (queryParams && !(queryParams instanceof Event)) {
      let queryString = Object.keys(queryParams)
        .map(key => key + '=' + queryParams[key])
        .join('&')
      url += '&' + queryString
    }

    // if in dev mode, add query dev=1
    if (process.env.VUE_APP_MODE === 'development') {
      url += '&dev=1'
    }

    // redirect
    location.href = url
  })
  return false
}

/**
 * Validates the user is authenticated and is valid
 */
const validate = async ({ commit }) => {
  // Check if the id_token (Refresh Token) is being passed
  const url = new URL(window.location.href)
  const refreshToken = url.searchParams.get('rtok')
  url.searchParams.delete('rtok')

  // replace state without reload
  // https://www.geeksforgeeks.org/how-to-modify-url-without-reloading-the-page-using-javascript/
  window.history.replaceState({}, '', url.toString())
  if (refreshToken) {
    const d = new Date()
    d.setFullYear(d.getFullYear() + 1)
    setRefreshToken(refreshToken, Math.round(d.getTime() / 1000)) // set the cookie for 1 year
    updateLastActivity()
  } else {
    let isValid = await checkLastActivity()
    if (!isValid) {
      return logout(undefined, { auto: 1 })
    }
  }

  const accessToken = await getAccessToken()
  if (!accessToken) {
    return redirectToLogin()
  }

  const { sub } = parseJWT(accessToken)
  const userProfile = await getUser({
    userId: sub,
  })
  commit('setAuthUser', userProfile)

  if (userProfile.email.includes('novorpm.com')) {
    commit('setIsNovoAdmin', true)
    for (let e in process.env) {
      console.log(
        `%c ${e}: %c "${process.env[e]}"`,
        'color: #bada55',
        'color: #FFCC00'
      )
    }
  }
  return true
}

const lastActivityCookieKey = 'last_activity'
const url = new URL(location.href)
const lastActivityTolerence = 3 * 60 * 1000 // 3 minutes of inactivity

const updateLastActivity = () => {
  setCookie(lastActivityCookieKey, Date.now())
}

const clearLastActivity = () => {
  setCookie(lastActivityCookieKey, '', Date.now())
}

const getLastActivity = () => {
  return Number(getCookie(lastActivityCookieKey) || 0)
}

const checkLastActivity = async () => {
  const lastActivity = getLastActivity()
  if (
    lastActivity &&
    Date.now() - lastActivity >= lastActivityTolerence &&
    (!document.referrer || url.host === location.host)
  ) {
    clearLastActivity()
    logout(undefined, { auto: 1 })
    return false
  }
  return true
}

export default {
  namespaced: true,
  state: {
    authUser: null,
    claims: null,
    isNovoAdmin: false,
    userSavedOrgId: null,
  },
  mutations: {
    setAuthUser(state, authUser) {
      state.authUser = authUser
      if (!authUser) state.userSavedOrgId = null
      else state.userSavedOrgId = localStorage[state.authUser.id+'_selectedOrgId'] || null
    },
    setClaims(state, claims) {
      state.claims = claims
    },
    setIsNovoAdmin(state, val) {
      state.isNovoAdmin = val
    },
    setUserSavedOrgId(state, orgId) {
      if (state.userSavedOrgId === orgId) return
      state.userSavedOrgId = orgId
      if (state.authUser) localStorage[state.authUser.id+'_selectedOrgId'] = orgId
    }
  },
  actions: {
    checkLastActivity,
    clearLastActivity,
    getLastActivity,
    logout,
    updateLastActivity,
    validate,
  },
}
