<template>
  <v-dialog v-model="showDialog" max-width="660" scrollable>
    <v-card v-if="candidateCopy">
      <v-toolbar dense color="grey lighten-4" flat>
        <v-toolbar-title>
          <v-row no-gutters align="center">
            <div class="mr-3">Enrollment</div>
          </v-row>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-form class="black--text pt-5">
          <v-row no-gutters align="center">
            <div class="form-label">Approved Devices</div>
            <v-col cols="12" class="pl-3">
              <v-chip
                label
                color="primary"
                outlined
                v-for="(item, index) in candidateCopy.devices"
                :key="index"
              >{{item | deviceName}}</v-chip>
            </v-col>
          </v-row>
          <v-row class="py-6" no-gutters>
            <div class="form-label">Shipping Information</div>
          </v-row>
          <v-row no-gutters class="px-3 mb-4">Please confirm the shipping information.</v-row>
          <v-row class="px-2">
            <v-col cols="12" class="px-3 pt-2">
              <v-text-field label="Name" dense outlined hide-details v-model="candidateCopy.name"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Street"
                dense
                outlined
                hide-details
                v-model="candidateCopy.address.line1"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Street #2 (Apt/Unit)"
                dense
                outlined
                hide-details
                v-model="candidateCopy.address.line2"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="City"
                dense
                outlined
                hide-details
                v-model="candidateCopy.address.city"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="STATES"
                label="State"
                outlined
                dense
                item-text="longName"
                item-value="shortName"
                hide-details
                v-model="candidateCopy.address.state"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Postal Code"
                outlined
                dense
                hide-details
                v-model="candidateCopy.address.zip"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pt-2" no-gutters>
            <div class="form-label">Notes</div>
          </v-row>
          <v-row class="pl-5 pr-5 mb-5">
            <v-textarea outlined hide-details no-resize rows="2" v-model="note"></v-textarea>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="busy" depressed color="success" class="px-8" @click="enroll">Enroll</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.form-label {
  padding-left: 12px;
  min-width: 100px;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
}
</style>

<script>
import bus from 'common/helpers/bus'
import moment from 'moment-timezone'
import clone from 'lodash/cloneDeep'
import { mapActions, mapState } from 'vuex'
import { STATUS_TYPES } from '@/consts'
import { STATES } from '@/consts'

export default {
  data() {
    return {
      busy: false,
      note: '',
      showDialog: false,
      STATES,
      candidateCopy: null,
    }
  },
  computed: {
    ...mapState('enrollment', ['startCallTime', 'currentCandidate']),
  },
  filters: {
    deviceName(val = '') {
      switch (val.toUpperCase()) {
        case 'BP CUFF':
          return 'Blood Pressure Cuff'
        case 'BLOOD GLUCOSE MONITOR':
          return 'Blood Glucose Monitor'
        case 'WEIGHT SCALE':
        case 'WEIGHT_SCALE':
          return 'Weight Scale'
      }
      return val
    },
    date(value, includeDay) {
      if (value) {
        if (includeDay) {
          return moment(value).format('dddd, MMMM D, YYYY')
        }
        return moment(value).format('MMMM D, YYYY')
      }
      return ''
    },
  },
  methods: {
    ...mapActions('enrollment', ['enrollCandidate', 'updateCandidateStatus']),
    async enroll() {
      this.busy = true
      let log = {
        start: new Date(this.startCallTime).toISOString(),
        end: new Date().toISOString(),
        status: STATUS_TYPES.ENROLLED,
        note: this.note,
      }
      try {
        await this.enrollCandidate({
          candidate: this.candidateCopy,
          devices: this.candidateCopy.devices,
          log,
        })
        this.showDialog = false
      } catch (e) {
        bus.$emit('message', {
          error: true,
          text: `An error occured: ${e.message}`,
          showClose: true,
        })
      }
      this.busy = false
    },
    openDialog() {
      this.candidateCopy = clone(this.currentCandidate)
      this.candidateCopy.name =
        this.candidateCopy.firstName + ' ' + this.candidateCopy.lastName
      this.showDialog = true
      this.note = ''
    },
    async updateStatus() {
      let log = {
        start: new Date(this.startCallTime).toISOString(),
        end: new Date().toISOString(),
        status: STATUS_TYPES.ENROLLED,
        note: this.note,
      }
      await this.updateCandidateStatus({
        id: this.candidateCopy.id,
        log,
      })
      this.candidateCopy.status = STATUS_TYPES.ENROLLED
      if (!this.candidateCopy.logs) {
        this.candidateCopy.logs = []
      }
      this.candidateCopy.logs.unshift(log)
      this.note = ''
      this.showDialog = false
      bus.$emit('candidateUpdated', this.candidateCopy)
    },
  },
  created() {
    bus.$on('enrollCandidate', this.openDialog)
  },
}
</script>