const STATUS_TYPES = {
  CALLBACK: 'callback',
  ENROLLED: 'enrolled',
  NO_ANSWER: 'no_answer',
  NOT_CONTACTED: 'not_contacted',
  NOT_INTERESTED: 'not_interested',
  NOT_PATIENT: 'not_patient',
  WRONG_PERSON: 'wrong_person',
  WRONG_PHONE: 'wrong_phone',
  CONTACT_PROVIDER: 'contact_provider',
  WILL_CONTACT_PROVIDER: 'will_contact_provider',
  OTHER: 'other',
}

let consts = {
  DEFAULT_STATUS: STATUS_TYPES.CALLBACK,
  DEFAULT_FILTER_LIST: [
    STATUS_TYPES.NO_ANSWER,
    STATUS_TYPES.NOT_CONTACTED,
    // STATUS_TYPES.CALLBACK,
  ],
  STATUS_TYPES,
  STATUS: [
    {
      longName: 'Not Contacted',
      shortName: STATUS_TYPES.NOT_CONTACTED,
    },
    {
      longName: 'Callback',
      shortName: STATUS_TYPES.CALLBACK,
    },
    {
      longName: 'No Answer',
      shortName: STATUS_TYPES.NO_ANSWER,
    },
    {
      longName: 'Wrong person',
      shortName: STATUS_TYPES.WRONG_PERSON,
    },
    {
      longName: 'Bad phone number',
      shortName: STATUS_TYPES.WRONG_PHONE,
    },
    {
      longName: 'No longer a patient',
      shortName: STATUS_TYPES.NOT_PATIENT,
    },
    {
      longName: 'Not interested',
      shortName: STATUS_TYPES.NOT_INTERESTED,
    },
    {
      longName: 'Enrolled',
      shortName: STATUS_TYPES.ENROLLED,
    },
  ],
  GENDER: [
    { longName: 'Male', shortName: 'MALE' },
    { longName: 'Female', shortName: 'FEMALE' },
    { longName: 'Unknown', shortName: '' },
  ],
  STATES: [
    {
      longName: 'Alabama',
      shortName: 'AL',
    },
    {
      longName: 'Alaska',
      shortName: 'AK',
    },
    {
      longName: 'American Samoa',
      shortName: 'AS',
    },
    {
      longName: 'Arizona',
      shortName: 'AZ',
    },
    {
      longName: 'Arkansas',
      shortName: 'AR',
    },
    {
      longName: 'California',
      shortName: 'CA',
    },
    {
      longName: 'Colorado',
      shortName: 'CO',
    },
    {
      longName: 'Connecticut',
      shortName: 'CT',
    },
    {
      longName: 'Delaware',
      shortName: 'DE',
    },
    {
      longName: 'District Of Columbia',
      shortName: 'DC',
    },
    {
      longName: 'Federated States Of Micronesia',
      shortName: 'FM',
    },
    {
      longName: 'Florida',
      shortName: 'FL',
    },
    {
      longName: 'Georgia',
      shortName: 'GA',
    },
    {
      longName: 'Guam',
      shortName: 'GU',
    },
    {
      longName: 'Hawaii',
      shortName: 'HI',
    },
    {
      longName: 'Idaho',
      shortName: 'ID',
    },
    {
      longName: 'Illinois',
      shortName: 'IL',
    },
    {
      longName: 'Indiana',
      shortName: 'IN',
    },
    {
      longName: 'Iowa',
      shortName: 'IA',
    },
    {
      longName: 'Kansas',
      shortName: 'KS',
    },
    {
      longName: 'Kentucky',
      shortName: 'KY',
    },
    {
      longName: 'Louisiana',
      shortName: 'LA',
    },
    {
      longName: 'Maine',
      shortName: 'ME',
    },
    {
      longName: 'Marshall Islands',
      shortName: 'MH',
    },
    {
      longName: 'Maryland',
      shortName: 'MD',
    },
    {
      longName: 'Massachusetts',
      shortName: 'MA',
    },
    {
      longName: 'Michigan',
      shortName: 'MI',
    },
    {
      longName: 'Minnesota',
      shortName: 'MN',
    },
    {
      longName: 'Mississippi',
      shortName: 'MS',
    },
    {
      longName: 'Missouri',
      shortName: 'MO',
    },
    {
      longName: 'Montana',
      shortName: 'MT',
    },
    {
      longName: 'Nebraska',
      shortName: 'NE',
    },
    {
      longName: 'Nevada',
      shortName: 'NV',
    },
    {
      longName: 'New Hampshire',
      shortName: 'NH',
    },
    {
      longName: 'New Jersey',
      shortName: 'NJ',
    },
    {
      longName: 'New Mexico',
      shortName: 'NM',
    },
    {
      longName: 'New York',
      shortName: 'NY',
    },
    {
      longName: 'North Carolina',
      shortName: 'NC',
    },
    {
      longName: 'North Dakota',
      shortName: 'ND',
    },
    {
      longName: 'Northern Mariana Islands',
      shortName: 'MP',
    },
    {
      longName: 'Ohio',
      shortName: 'OH',
    },
    {
      longName: 'Oklahoma',
      shortName: 'OK',
    },
    {
      longName: 'Oregon',
      shortName: 'OR',
    },
    {
      longName: 'Palau',
      shortName: 'PW',
    },
    {
      longName: 'Pennsylvania',
      shortName: 'PA',
    },
    {
      longName: 'Puerto Rico',
      shortName: 'PR',
    },
    {
      longName: 'Rhode Island',
      shortName: 'RI',
    },
    {
      longName: 'South Carolina',
      shortName: 'SC',
    },
    {
      longName: 'South Dakota',
      shortName: 'SD',
    },
    {
      longName: 'Tennessee',
      shortName: 'TN',
    },
    {
      longName: 'Texas',
      shortName: 'TX',
    },
    {
      longName: 'Utah',
      shortName: 'UT',
    },
    {
      longName: 'Vermont',
      shortName: 'VT',
    },
    {
      longName: 'Virgin Islands',
      shortName: 'VI',
    },
    {
      longName: 'Virginia',
      shortName: 'VA',
    },
    {
      longName: 'Washington',
      shortName: 'WA',
    },
    {
      longName: 'West Virginia',
      shortName: 'WV',
    },
    {
      longName: 'Wisconsin',
      shortName: 'WI',
    },
    {
      longName: 'Wyoming',
      shortName: 'WY',
    },
  ],
}
module.exports = consts
