<template>
  <v-app>
    <v-app-bar v-if="candidates" app dense class="elevation-1" color="white">
      <!-- <Logo :dark="true" class="clickable" @click.native="goToApp" /> -->
      <div class="text-uppercase pt-1" style="width:100%">
        <v-row no-gutters align="center">
          <div v-if="orgs" class="pt-2">
            <v-select
              :items="userOrgs"
              label="Select organization..."
              item-text="name"
              item-value="id"
              v-model="selectedOrgId"
              flat
              dense
              solo
              hide-details
              color="grey darken-2"
              background-color="grey lighten-4"
              style="font-weight: bold;max-width: 300px"
            ></v-select>
          </div>
          <div class="flex-grow-1 px-12">
            <v-row no-gutters justify="center">
              <v-text-field
                placeholder="Search candidates..."
                solo
                flat
                dense
                hide-details
                clearable
                prepend-inner-icon="mdi-magnify"
                color="grey darken-2"
                background-color="grey lighten-4"
                style="max-width: 500px"
                @input="search"
              ></v-text-field>
            </v-row>
          </div>
        </v-row>
      </div>

      <!-- App Switcher -->
      <app-switcher></app-switcher>

      <!-- Profile Menu -->
      <profile-menu></profile-menu>

      <template v-slot:extension>
        <v-tabs :value="selectedTabIndex" show-arrows centered @change="setSelectedTabIndex">
          <v-tab id="candidates">Candidates</v-tab>
          <v-tab id="callbacks">
            <v-icon small left>mdi-phone-outline</v-icon>Callbacks
          </v-tab>
        </v-tabs>
        <v-switch
          :input-value="showTimeAgo"
          dense
          hide-details
          inset
          color="primary"
          class="switch-font pa-0 mb-n1 ma-0 pr-2"
          style="position:absolute;bottom:12px;right:12px"
          @change="setShowTimeAgo"
        >
          <template v-slot:label>
            <v-icon class="ml-n3 mt-n1">mdi-timelapse</v-icon>
          </template>
        </v-switch>
      </template>
    </v-app-bar>

    <v-main>
      <v-container fluid class="pa-0">
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>

    <!-- Dev Mode Indicator -->
    <dev-mode-indicator v-if="isDevMode"></dev-mode-indicator>

    <!-- Auto Logout -->
    <auto-logout></auto-logout>

    <!-- Snackbar Message -->
    <snackbar-message></snackbar-message>
  </v-app>
</template>

<style lang="scss">
.clickable {
  cursor: pointer;
}

.v-btn,
.v-tab {
  text-transform: capitalize !important;
}

.link {
  text-decoration: none;
}
</style>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
// import Logo from 'common/components/Logo'
import ProfileMenu from 'common/components/ProfileMenu'
import AutoLogout from 'common/components/AutoLogout'
import AppSwitcher from 'common/components/AppSwitcher'
import DevModeIndicator from 'common/components/DevModeIndicator'
import SnackbarMessage from 'common/components/SnackbarMessage'
// import { state as ssoState } from 'common/store/sso'
// import {state as orgState } from 'common/store/org'

let timer
export default {
  name: 'App',

  components: {
    AppSwitcher,
    AutoLogout,
    DevModeIndicator,
    // Logo,
    ProfileMenu,
    SnackbarMessage,
  },

  data: () => ({
    drawer: false,
    // orgState,
    selectedOrgId: null,
    showRightDrawer: true,
    snackbar: {
      success: false,
      error: false,
      text: '',
      show: false,
      timeout: 3000,
      showClose: false,
    },
    tab: 'candidates',
  }),

  computed: {
    ...mapState(['showTimeAgo']),
    ...mapState(['selectedTabIndex']),
    ...mapState('auth', ['authUser', 'userSavedOrgId']),
    ...mapState('enrollment', ['candidates']),
    ...mapState('orgs', ['orgs']),
    isDevMode() {
      return  process.env.VUE_APP_RPM_API_URL !== 'https://api.rpm.novorpm.com'
    },
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    userOrgs() {
      if (!this.authUser || !this.orgs) return []
      const userOrgIds = new Set()
      for (const uo of this.authUser.orgs) userOrgIds.add(uo.orgId) 
      return this.orgs.filter(org => userOrgIds.has(org.id))
    },
  },

  methods: {
    ...mapActions(['setSelectedTabIndex', 'setShowTimeAgo', 'setSearchInput']),
    ...mapActions('auth', ['logout']),
    ...mapActions('enrollment', ['getCandidates']),
    ...mapActions('orgs', ['getOrgs']),
    ...mapActions('timezone', ['getTimezones']),
    ...mapMutations('auth', ['setUserSavedOrgId']),
    navigateTo(name) {
      if (this.$route.name === name) {
        // close the navigation drawer only
        this.drawer = false
      } else {
        // navigate to the page which will close the drawer also
        this.$router.push({ name })
      }
    },
    goToApp() {
      location.href = '/'
    },
    search(text) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.setSearchInput(text)
      }, 350)
      this.setSelectedTabIndex(0)
    },
  },
  watch: {
    selectedOrgId(newVal, oldVal) {
      if (oldVal) this.setUserSavedOrgId(newVal)  // a previous oldVal means that this was a deliberate change
      this.getCandidates({ orgId: newVal })
    },
    userOrgs() {
      if (!this.userOrgs) return
      let toSelect = this.userOrgs[0].id
      if (this.userSavedOrgId) toSelect = this.userOrgs.find(o => o.id === this.userSavedOrgId)?.id || toSelect
      this.selectedOrgId = toSelect
    },
  },

  created() {
    this.getTimezones()
    this.$vuetify.theme.dark = false
  },
}
</script>
