<template>
  <v-dialog
    v-model="showDialog"
    max-width="480"
    persistent
    no-click-animation
    overlay-color="#333"
    overlay-opacity="0.05"
    content-class="elevation-4"
  >
    <v-card>
      <v-card-title>Error</v-card-title>
      <v-card-text class="body-1">{{text}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed @click="logout">Go to login</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: () => {
    return {
      text: '',
      showDialog: false,
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    open(text) {
      this.text = text
      this.showDialog = true
    },
  },
}
</script>