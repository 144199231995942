import { HttpClient } from 'common/helpers/httpClient'
import Vue from 'vue'
import clone from 'lodash/clone'
import consts from '@/consts'
import { getAccessToken } from 'common/store/sso'
import store from '@/store'

const requestHandler = async (ctx, request) => {
  const accessToken = await getAccessToken()
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`
  } else {
    store.dispatch('auth/logout', { error: 'Invalid refresh token' })
  }
  return request
}

const rpmClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_RPM_API_URL,
  requestHandler,
})

const patientsClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_PATIENTS_API_URL,
  requestHandler,
})

function prepCandidate(candidate) {
  candidate.logs = candidate.logs || []
  candidate.lastNote = (candidate.logs[candidate.logs.length - 1] || {}).note || ''
  candidate.address = candidate.address || { line1: '', line2: '', city: '', state: '', zip: '' }
}

export default {
  namespaced: true,
  state: {
    currentCandidate: null,
    candidates: null,
    startCallTime: 0,
    currentPatient: null,
  },
  mutations: {
    setCurrentCandidate(state, candidate) {
      if (!candidate) {
        state.currentCandidate = null
        return
      }
      if (state.currentCandidate?.id === candidate?.id) return
      prepCandidate(candidate)
      state.currentCandidate = clone(candidate)
    },
    setCandidates(state, candidates) {
      state.candidates = candidates
    },
    setStartCallTime(state, time) {
      state.startCallTime = time
    },
    updateCandidate(state, candidate) {
      let candidates = state.candidates
      for (let i = 0; i < candidates.length; i++) {
        if (candidates[i].id === candidate.id) {
          prepCandidate(candidate)
          Vue.set(candidates, i, candidate)
          break
        }
      }
    },
    setCurrentPatient(state, patient) {
      state.currentPatient = patient
    },
  },
  actions: {
    async initCallTime({ commit }) {
      commit('setStartCallTime', new Date())
    },
    async getCandidates({ commit }, { orgId }) {
      const { data } = await rpmClient.post('/GetCandidates', {
        orgId,
        status: [
          consts.STATUS_TYPES.CALLBACK,
          consts.STATUS_TYPES.NO_ANSWER,
          consts.STATUS_TYPES.NOT_CONTACTED,
        ],
      })
      if (data && data.candidates) {
        for (const candidate of data.candidates) prepCandidate(candidate)
        commit('setCandidates', data.candidates)
      } else {
        commit('setCandidates', [])
      }
    },
    async getCandidate(_, { id }) {
      try {
        let { data } = await rpmClient.post('/GetCandidate', { id })
        return data.candidate
      } catch (e) {
        return null
      }
    },
    async getPatient({ commit }, { id = '', orgId = '', orgPatientId = '', smsPhone = '' }) {
      try {
        let { data } = await patientsClient.post('/GetPatient', {
          id,
          orgId,
          orgPatientId,
          smsPhone,
        })
        commit('setCurrentPatient', data.patient)
        return data.patient
      } catch (e) {
        commit('setCurrentPatient', null)
        return null
      }
    },
    async createPatient({ commit }, candidate) {
      let { data } = await patientsClient.post('/CreatePatient', {
        orgId: candidate.owner.id,
        orgPatientId: candidate.ownerPatientId,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        gender: candidate.gender,
        birthdate: candidate.birthdate,
        primaryPhone: candidate.primaryPhone,
        smsPhone: candidate.smsPhone,
        email: candidate.email,
        address: candidate.address,
        conditions: candidate.conditions,
      })
      commit('setCurrentPatient', data.patient)
    },
    async createOrder(_, { candidate, patientId, deviceName }) {
      let { data } = await rpmClient.post('/CreateOrder', {
        owner: candidate.owner,
        ownerPatientId: candidate.ownerPatientId,
        shippingRecipientName: `${candidate.firstName} ${candidate.lastName}`,
        shippingAddress: candidate.address,
        patientId,
        deviceName,
      })
      return data.order
    },
    async assignDevice(_, { patientId, kind, refId }) {
      let { data } = await patientsClient.post('/AssignDevice', {
        patientId,
        kind,
        deviceRefId: refId,
      })
      return data.device
    },
    async getEnrollmentStatus({ dispatch, state }, { candidate: filter }) {
      await dispatch('getCandidate', { id: filter.id })
      if (state.currentCandidate?.status === 'enrolled') return true
      if (state.currentPatient?.org?.patientId === state.currentCandidate?.ownerPatientId) {
        await dispatch('getPatient', {
          id: filter.patientId,
          orgId: filter.owner.id,
          orgPatientId: filter.ownerPatientId,
        })
      }
      return !!state.currentPatient?.rpm?.candidate?.enrolled
    },
    async updateCandidate({ commit }, candidate) {
      const resp = await rpmClient.post('/UpdateCandidate', candidate)
      commit('setCurrentCandidate', candidate)
      commit('updateCandidate', candidate)
      return resp
    },
    async updateCandidateStatus({ commit }, { id, log }) {
      let { data } = await rpmClient.post('/UpdateCandidateStatus', {
        id,
        ...log,
      })
      let candidate = data.candidate
      commit('setCurrentCandidate', null)
      commit('updateCandidate', candidate)
    },
    async enrollCandidate({ dispatch, state }, { candidate, devices, log }) {
      if (state.currentPatient?.org?.patientId !== candidate?.ownerPatientId) {
        await dispatch('createPatient', candidate)
      }
      devices.forEach(async device => {
        await dispatch('createOrder', {
          candidate,
          patientId: state.currentPatient.id,
          deviceName: device,
        })
      })
      dispatch('updateCandidateStatus', { id: candidate.id, log })
    },
    setCurrentCandidate({ commit }, candidate) {
      commit('setCurrentCandidate', candidate)
    },
  },
}
